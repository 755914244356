<template>
  <div class="d-flex">
    <div v-if="active" class="d-flex">
      <v-menu
        v-model="dateFromMenu"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template #activator="{ on, attrs }">
          <v-text-field
            v-model="dateFrom"
            :label="t('filters.date_from')"
            prepend-icon="mdi-calendar"
            readonly
            dense
            v-bind="attrs"
            class="me-4"
            single-line
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker v-model="dateFrom" @input="dateFromMenu = false"></v-date-picker>
      </v-menu>

      <v-menu
        v-model="dateToMenu"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template #activator="{ on, attrs }">
          <v-text-field
            v-model="dateTo"
            :label="t('filters.date_to')"
            prepend-icon="mdi-calendar"
            readonly
            dense
            v-bind="attrs"
            class="me-4"
            single-line
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker v-model="dateTo" @input="dateToMenu = false"></v-date-picker>
      </v-menu>

      <v-select
        v-model="groupBy"
        :items="groupByItems"
        dense
        :label="t('filters.group_by')"
        class="me-4"
        single-line
      ></v-select>
    </div>

    <v-switch v-model="active" :label="t('filters.switcher')" class="mt-0"></v-switch>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dateFrom: null,
      dateFromMenu: false,
      dateTo: null,
      dateToMenu: false,
      groupBy: "day",
      active: false
    };
  },

  computed: {
    /**
     * Apptype статистики
     */
    appType() {
      return this.$route.params.app;
    },

    /**
     * Данные для выбора группировки
     */
    groupByItems() {
      return [
        { text: this.t("filters.group.day"), value: "day" },
        { text: this.t("filters.group.week"), value: "week" },
        { text: this.t("filters.group.month"), value: "month" }
      ];
    }
  },

  watch: {
    active() {
      this.emitUpdate();
    },

    appType() {
      this.reset();
      this.active = false;
    },

    dateFrom() {
      this.emitUpdate();
    },

    dateTo() {
      this.emitUpdate();
    },

    groupBy() {
      this.emitUpdate();
    }
  },

  methods: {
    /**
     * Получение перевода текущей группы
     */
    t(key, params) {
      return this.$t("pages.videos." + key, params);
    },

    /**
     * Получение данных фильтра
     * @return {{from: null, to: null, group: string}}
     */
    getFilterData() {
      if (this.active == false) {
        return {};
      }
      return {
        from: this.dateFrom,
        to: this.dateTo,
        group: this.groupBy
      };
    },

    /**
     * Сброс фильтров в начальное положение
     */
    reset() {
      this.dateFrom = null;
      this.dateTo = null;
      this.groupBy = "day";
    },

    /**
     * Отправка сообщения
     */
    emitUpdate() {
      this.$emit("update", this.getFilterData());
    }
  }
};
</script>

<style lang="scss" scoped>
.v-input {
  max-width: 10em;
}
</style>
