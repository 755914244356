<template>
  <div>
    <div class="d-flex mb-2">
      <div class="ma-0 body-2 text--disabled">
        <span class="text-uppercase">{{ t("today_stat.title") }}</span> {{ t("today_stat.sub_title") }}
      </div>
      <v-spacer />
      <v-chip color="grey lighten-5" @click="loadTodayData">
        {{ t("today_stat.refresh") }}
        <v-avatar right> <v-icon color="blue">mdi-reload</v-icon> </v-avatar>
      </v-chip>
    </div>

    <base-card>
      <v-card-text class="pt-0 pb-0">
        <v-row align="center">
          <v-col cols="12">
            <v-row>
              <v-col
                v-for="(block, index) in blocks"
                :key="index"
                :cols="$vuetify.breakpoint.smAndDown ? 12 : null"
                class="d-flex flex-center align-center"
              >
                <div class>
                  <p class="mb-0  me-4">
                    {{ block.label }}
                  </p>
                </div>
                <h1 :class="[$vuetify.breakpoint.lgAndUp ? 'text-h4' : 'text-h5']" class="font-weight-thin mb-0">
                  <template v-if="todayData">{{ block.data }}</template>
                  <v-progress-circular v-else indeterminate color="primary"></v-progress-circular>
                </h1>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </base-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      todayData: null
    };
  },

  computed: {
    appType() {
      return this.$route.params.app;
    },

    blocks() {
      return [
        { label: this.t("today_stat.views"), data: this.todayData ? this.todayData.views : "" },
        { label: this.t("today_stat.viewers"), data: this.todayData ? this.todayData.viewers : "" },
        { label: this.t("today_stat.new_videos"), data: this.todayData ? this.todayData.new_videos : "" },
        { label: this.t("today_stat.sales"), data: this.todayData ? this.todayData.sales : "" }
      ];
    }
  },

  watch: {
    appType() {
      this.loadTodayData();
    }
  },

  mounted() {
    this.loadTodayData();
  },

  methods: {
    t(key, params) {
      return this.$t("pages.videos." + key, params);
    },
    loadTodayData() {
      this.todayData = null;
      this.$store
        .dispatch("videos/loadTodayData", { app: this.appType })
        .then(this.processTodayData)
        .catch(this.catchTodayData);
    },
    processTodayData(response) {
      this.todayData = response.data.data;
    },
    catchTodayData() {}
  }
};
</script>
