/**
 * Общие методы для таблиц с данными
 */
export default {
  data() {
    return {
      loading: false,
      items: [],
      additional: null,
      total: 0,
      options: {
        itemsPerPage: 25,
        sortBy: ["id"],
        sortDesc: [true]
      },
      oldPageValue: 1,
      footerProps: {
        itemsPerPageOptions: [5, 10, 25, 100]
      }
    };
  },

  methods: {
    /**
     * Формирование базовых параметров для загрузки данных
     * @return {{per_page, page, sort_key: (*|string), sort_dir: (string)}}
     */
    getBaseParams() {
      return {
        page: this.options.page,
        per_page: this.options.itemsPerPage,
        sort_key: this.options.sortBy.length > 0 ? this.options.sortBy[0] : "id",
        sort_dir: this.options.sortDesc.length > 0 ? (this.options.sortDesc[0] ? "desc" : "asc") : "desc"
      };
    },

    /**
     * Обработка данных от сервера
     */
    processDataLoadResponse(response) {
      this.items = response.data.data;
      this.total = response.data.meta.total;
      if (typeof response.data.additional != "undefined") {
        this.additional = response.data.additional;
      }
      this.loading = false;
    },

    /**
     * Обработка ошибки при загрузке данных
     */
    catchDataLoadResponse() {
      this.loading = false;
    },

    /**
     * Изменение сортировки пагинации или числа строк на страницу
     * @param options
     */
    onOptionsUpdate(options) {
      // Если это НЕ переключение страницы, а сортировка или изменение числа строк
      if (options.page === this.oldPageValue) {
        this.options.page = 1;
      }
      this.oldPageValue = this.options.page;
      this.loadData();
    }
  }
};
