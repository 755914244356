<template>
  <apexchart type="line" height="350" :options="options" :series="series" />
</template>

<script>
export default {
  props: {
    values: {
      type: String,
      default: "views"
    },
    rawData: {
      type: Array,
      default: new Array()
    }
  },
  data() {
    return {};
  },
  computed: {
    options() {
      let mapping = {
        views: "#4CA8FF",
        viewers: "#F14004",
        sales: "#43A047"
      };
      let color = mapping[this.values];

      return {
        chart: {
          type: "line",
          toolbar: {
            show: false
          }
        },
        colors: [color],
        stroke: {
          curve: "smooth",
          width: [6]
        },
        dataLabels: {
          enabled: false
        },
        markers: {
          size: [5],
          hover: { sizeOffset: 2 }
        },
        title: {
          text: ""
        },
        xaxis: {
          type: "category"
        },
        yaxis: []
      };
    },

    series() {
      let result = [];
      let mapping = {
        views: "views",
        viewers: "viewers",
        sales: "sales"
      };

      let key = mapping[this.values];

      this.rawData.forEach(row => {
        result.push({
          x: row.id,
          y: row[key]
        });
      });

      return [
        {
          name: this.t(`chart.target_select.${this.values}`),
          data: result.sort(this.compareNodes)
        }
      ];
    }
  },

  methods: {
    t(key, params) {
      return this.$t("pages.videos." + key, params);
    },
    compareNodes(a, b) {
      if (a.x < b.x) {
        return -1;
      }
      if (a.x > b.x) {
        return 1;
      }
      return 0;
    }
  }
};
</script>
