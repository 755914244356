<template>
  <div>
    <v-row>
      <v-col cols="12">
        <base-card>
          <v-card-text class="v-card__text--dense">
            <div class="d-flex justify-space-between align-center">
              <div class="card-title ma-0">
                <span class="text-capitalize">{{ $t("titles.videos") }}</span>
                <a :href="'/page-video'" class="ml-1 text-sm-subtitle-2">{{ t("subTitle") }}</a>
              </div>
              <v-breadcrumbs :items="routePath">
                <template #divider>
                  <v-icon>mdi-chevron-right</v-icon>
                </template>
              </v-breadcrumbs>
            </div>
          </v-card-text>
        </base-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <today-statistics></today-statistics>
      </v-col>
    </v-row>
    <v-row>
      <v-col :cols="12">
        <div class="d-flex mb-2">
          <div class="ma-0 body-2 text--disabled">
            <span class="text-caption mt-2 text-uppercase text--disabled">{{ t("chart.title") }}</span>
          </div>
        </div>
        <v-card height="100%">
          <v-card-title class="justify-space-between align-start">
            <div class="mr-1">
              <v-select
                v-model="chartType"
                :items="chartTypes"
                :label="t('chart.target_select.label')"
                outlined
                dense
              ></v-select>
            </div>
            <div>
              <v-select
                v-model="chartPlayerType"
                :items="chartPlayerTypes"
                :label="t('chart.player_select.label')"
                outlined
                dense
                @change="loadData"
              ></v-select>
            </div>
            <v-spacer/>
            <filters-block @update="onFilterUpdate"></filters-block>
          </v-card-title>
          <v-card-text class="ps-4 pe-4">
            <chart :raw-data="items" :values="chartType"></chart>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title class="justify-space-between">
            <div class="card-title ma-0 body-2 text--disabled text-uppercase">{{ t("table.title") }}</div>
            <v-spacer />
          </v-card-title>

          <v-card-text>
            <v-data-table
              :headers="headers"
              :items="items"
              :options.sync="options"
              :server-items-length="total"
              :items-per-page="options.itemsPerPage"
              :loading="loading"
              :footer-props="footerProps"
              item-key="date"
              @update:options="onOptionsUpdate"
            >
            </v-data-table>
            <div class="body-2" v-html="t('notes.reference')"></div>
            <div class="body-2" v-html="t('notes.embed')"></div>
            <div class="body-2" v-html="t('notes.on_site')"></div>
            <div class="body-2" v-html="t('notes.full')"></div>
            <div class="body-2" v-html="t('notes.demo')"></div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

  </div>
</template>

<script>
import {mapGetters} from "vuex";
import dataTable from "@/views/mixins/dataTable";
import todayStatistics from "./TodayStatistics";
import chart from "./Chart";
import filtersBlock from "./Filters";

export default {
  components: {
    todayStatistics,
    chart,
    filtersBlock
  },
  metaInfo() {
    return {
      title: this.$t("titles.videos")
    };
  },
  mixins: [dataTable],
  data() {
    return {
      loadingData: false,
      currentPage: 1,
      itemsPerPage: 10,
      totalItems: 0,
      items: [],
      filtersVisible: false,
      filters: {},
      folders: [],
      chartType: "views",
      chartPlayerType: "all",
      loading: true
    };
  },
  computed: {
    ...mapGetters("app", ["appDomain", "appTitle"]),
    appType() {
      return this.$route.params.app.toLowerCase();
    },
    currentRouteName() {
      return this.$route.name;
    },
    routePath() {
      return [
        {
          text: this.$t("app.sections.dashboard"),
          disabled: false,
          href: "/"
        },
        {
          text: this.appTitle(this.appType),
          disabled: false,
          href: "/"
        },
        {
          text: this.$t("app.sections.videos"),
          disabled: true,
          href: "/"
        }
      ];
    },
    chartTypes() {
      return [
        {text: this.t("chart.target_select.views"), value: "views"},
        {text: this.t("chart.target_select.viewers"), value: "viewers"},
        {text: this.t("chart.target_select.sales"), value: "sales"}
      ];
    },
    chartPlayerTypes() {
      return [
        {text: this.t("chart.player_select.all"), value: "all"},
        {text: this.t("chart.player_select.embed"), value: "embed"},
        {text: this.t("chart.player_select.on_site"), value: "on-site"},
        {text: this.t("chart.player_select.full"), value: "full"},
        {text: this.t("chart.player_select.demo"), value: "demo"}
      ];
    },
    headers() {
      let columns = [
        {
          text: this.t("table.fields.date"),
          value: "id",
          sortable: true,
          width: "10em"
        },
        {
          text: this.t("table.fields.video_files"),
          value: "video_files",
          sortable: true
        },
        {
          text: this.t("table.fields.views"),
          value: "views",
          sortable: true
        },
        {
          text: this.t("table.fields.viewers"),
          value: "viewers",
          sortable: true
        },
        {
          text: this.t("table.fields.viewers_embed"),
          value: "viewers_embed",
          sortable: true
        },
        {
          text: this.t("table.fields.sales"),
          value: "sales",
          sortable: true
        },
        {
          text: this.t("table.fields.sum"),
          value: "sum",
          sortable: true
        }
      ];
      return columns;
    }
  },
  watch: {
    $route() {
      this.loadData();
    }
  },
  mounted() {
    this.loadData();
  },
  methods: {
    t(key, params) {
      return this.$t("pages.videos." + key, params);
    },
    getLoadingParams() {
      let data = {
        app: this.appType,
        playerType: this.chartPlayerType
      };
      ["from", "to", "group"].forEach(name => {
        if (this.filters[name]) {
          data[name] = this.filters[name];
        }
      });

      return Object.assign(this.getBaseParams(), data);
    },
    loadData() {
      this.loading = true;
      this.$store
        .dispatch("videos/loadVideosData", this.getLoadingParams())
        .then(this.processDataLoadResponse)
        .catch(this.catchDataLoadResponse);
    },
    refreshData() {
      if (this.loadingData) {
        return;
      }

      this.loadData();
    },
    onFilterUpdate(data) {
      this.filters = data;
      if (this.options.page != 1) {
        this.oldPageValue = this.options.page = 1;
      } else {
        this.loadData();
      }
    }
  }
};
</script>
